<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE STATUS -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    return-object
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterProductState',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                status: [{ id: 1, name: i18n.t('tools.groups.status.active') }]
            },
            activeFilters: {},
            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        optionsStatus() {
            return this.$store.getters['label/getFilterProductStateStatus']()
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('label/setFiltersProductStateActive', this.currentFilters)
            this.$store
                .dispatch('label/loadStates', {
                    name: definitiveFilters.name,
                    status: definitiveFilters.status
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('label/setFiltersProductStateActive', this.currentFilters)

            this.changesDetected = false
            this.$store.dispatch('label/loadStates', { status: 1 }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['label/getFiltersProductStateActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
